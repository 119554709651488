export default (size, callback) => {
    const sizes = ['tablet']

    let sizeAmount
    if (sizes.includes(size)) {
        sizeAmount = getComputedStyle(document.documentElement).getPropertyValue(`--${size}-width`)
    } else {
        sizeAmount = size
    }

    callback(!window.matchMedia(`(min-width: ${sizeAmount})`).matches)
    window.matchMedia(`(min-width: ${sizeAmount})`).addEventListener('change', ({ matches }) => callback(!matches))
}
