import styles from './Media.module.scss'
import classNames from 'classnames/bind'

import { forwardRef } from 'react'
const classes = classNames.bind(styles)

const Media = forwardRef(({ attributes, fill = false, className, ...props }, ref) => {
    if (attributes == null) return null

    let imageUrl = attributes.url

    if (attributes.provider == 'local') {
        imageUrl = process.env.NEXT_PUBLIC_STRAPI_IMAGE_URL + attributes.url
    }

    if (attributes.mime.includes('video')) {
        return (
            <video className={className} alt={attributes.alternativeText} autoPlay muted loop ref={ref}>
                <source src={imageUrl} />
            </video>
        )
    }

    return (
        <img
            className={classes({ fill }, className)}
            src={imageUrl}
            alt={attributes.alternativeText}
            {...props}
            ref={ref}
            loading="lazy"
        />
    )
})
export default Media
