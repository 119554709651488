'use client'

import { useEffect, useState, useRef } from 'react'
import WindowSizeListener from '@/components/Helpers/WindowSizeListener/WindowSizeListener'
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import styles from './Gradient.module.scss'
import classNames from 'classnames/bind'

const classes = classNames.bind(styles)

const Gradient = ({ id, background = false, className }) => {
    const sectionRef = useRef(null)
    const [gradientHeight, setGradientHeight] = useState(0)
    const [mobile, setMobile] = useState(null)

    const updateGradientHeight = () => {
        try {
            let heightOffset = 0
            let elementToCheck = document.getElementById('gradient-end')

            while (elementToCheck) {
                heightOffset += elementToCheck.offsetTop
                elementToCheck = elementToCheck.offsetParent
            }

            const newGradientHeight = heightOffset
            setGradientHeight(newGradientHeight)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        if (!background) {
            const observer = new ResizeObserver(updateGradientHeight).observe(document.querySelector('main'))
            updateGradientHeight()
        }

        WindowSizeListener('763px', setMobile)
        return () => {
            try {
                observer.disconnect()
            } catch (e) {}
        }
    }, [])

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                updateGradientHeight()
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.01],
        },
    })

    let styleTag = {
        opacity: 1,
    }

    if (!background) {
        styleTag = {
            height: `${gradientHeight}px`,
            opacity: gradientHeight != 0 ? 1 : 0,
        }
    }

    return (
        <div
            className={classes('gradient-wrapper', { background }, className)}
            style={styleTag}
            id={id}
            ref={sectionRef}
        >
            {mobile != true && (
                <video
                    className={classes('gradient-video', { background, visible: mobile == false })}
                    preload="none"
                    autoPlay
                    muted
                    loop
                    playsInline
                >
                    {mobile == false && <source src="/videos/gradient.webm" type="video/webm" />}
                </video>
            )}
        </div>
    )
}
export default Gradient
