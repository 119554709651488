import styles from './SectionWrap.module.scss'
import classNames from 'classnames/bind'
import React from 'react'

const classes = classNames.bind(styles)

const SectionWrap = React.forwardRef(({ children, styling = {}, className, sectionClassName, ...props }, ref) => {
    const stylingObject = styling ? styling : {}
    const { blueBackground, border } = stylingObject

    return (
        <div
            className={classes(
                'section',
                {
                    bordered: border,
                },
                sectionClassName
            )}
        >
            {blueBackground && <div className={classes('blue-background')}></div>}
            <div className={classes('wrap', className)} {...props} ref={ref}>
                {children}
            </div>
        </div>
    )
})

export default SectionWrap
