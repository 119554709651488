'use client'

const { useRef, forwardRef, useState, useEffect } = require('react')
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import Title from '@/components/Elements/Title/Title'
import Media from '@/components/Layout/Media/Media'
import Icon from '@/components/Elements/Icon/Icon'
import Button from '@/components/Links/Button/Button'

import ChevronRight from '@/icons/chevron-right.svg'
import ChevronLeft from '@/icons/chevron-left.svg'

import styles from './BlogPostsGrid.module.scss'
import classNames from 'classnames/bind'

const classes = classNames.bind(styles)

const BlogPostsGrid = ({ attributes = {}, index, tag = false, wide = false, blogPage = false }) => {
    const sectionRef = useRef(null)
    const boxes = useRef([])
    const [activeTab, setActiveTab] = useState(0)

    const [blogs, setBlogs] = useState({ data: [] })

    const [width, setWidth] = useState(2000)

    useEffect(() => {
        setWidth(window.innerWidth)
        window.addEventListener('resize', () => setWidth(window.innerWidth))
        return () => window.removeEventListener('resize', () => setWidth(window.innerWidth))
    }, [])

    useEffect(() => {
        const getInitialBlogs = async () => {
            setBlogs(await getBlogs(activeTab + 1, tag, blogPage))

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            boxes.current.forEach((box, i) => {
                                setTimeout(() => {
                                    box.classList.add(classes('enter'))
                                }, i * 100)
                            })
                        }
                    })
                },
                { threshold: [0.1] }
            )

            observer.observe(sectionRef.current)

            // Cleanup when the component unmnounts
            return () => observer.disconnect()
        }

        getInitialBlogs()
    }, [activeTab])

    return (
        <SectionWrap
            ref={sectionRef}
            className={classes('wrapper', { 'no-padding': wide, 'no-top-padding': blogPage })}
        >
            {index !== 0 && attributes?.title?.alignment == 'left'
                ? attributes?.title && (
                      <div className={classes('title-wrap')}>
                          <Title {...attributes.title} />
                          {width > 768 && <Button text={'View all articles'} url={'/blog'} />}
                      </div>
                  )
                : attributes?.title && <Title {...attributes.title} />}
            <div className={classes('grid', { 'shadow-padding': wide })}>
                {blogs.data.map((item, index) => {
                    return (
                        <Post
                            {...item.attributes}
                            id={index}
                            key={`blog_${item.id}`}
                            ref={(el) => (boxes.current[index] = el)}
                        />
                    )
                })}
            </div>
            {blogPage && pagination(activeTab, setActiveTab, blogs?.pagination?.pageCount)}
            {width < 768 && !blogPage && (
                <Button text={'View all articles'} url={'/blog'} extraClasses={classes('small-button')} />
            )}
        </SectionWrap>
    )
}

const Post = forwardRef(({ id, title, excerpt, slug, author, publishedAt, categoryGraphic }, ref) => {
    const layout = [4, 1, 1, 1, 1, 1, 1, 4, 1, 1]

    const authorData = author?.data?.attributes
    const publishedDate = new Date(publishedAt)

    const month = publishedDate.toLocaleString('default', { month: 'short' })

    const formattedDate = `${month} ${publishedDate.getDate()}, ${publishedDate.getFullYear()}`

    return (
        <a className={classes('option', `option_${layout[id]}`)} href={`/blog/${slug}`} ref={ref}>
            {layout[id] == 4 && (
                <img src={`/images/3d/${categoryGraphic || 'Plane'}.png`} className={classes('image')} />
            )}
            {layout[id] == 4 && (
                <div className={classes('tag', 'top')}>
                    <span>Read more</span>
                    <Icon icon={'arrow-right'} />
                </div>
            )}
            <div className={classes('title')}>{title}</div>
            <div className={classes('description')}>{excerpt}</div>
            {layout[id] == 4 && authorData ? (
                <div className={classes('author')}>
                    <div className={classes('avatar')}>
                        {authorData?.avatar?.data && (
                            <Media width={48} height={48} attributes={authorData?.avatar?.data?.attributes} />
                        )}
                    </div>
                    <div className={classes('author-details')}>
                        <div className={classes('name')}>{authorData?.displayName}</div>
                        <div className={classes('publish-date')}>{formattedDate}</div>
                    </div>
                </div>
            ) : (
                <div className={classes('tag', 'bottom')}>
                    <span>Read more</span>
                    <Icon icon={'arrow-right'} />
                </div>
            )}
        </a>
    )
})

const pagination = (activeTab, setActiveTab, numberOfTabs = 1) => {
    return (
        <div className={classes('pagination')}>
            <div
                className={classes('svg-wrapper')}
                onClick={() => {
                    const nextTabIndex = activeTab == 0 ? numberOfTabs - 1 : activeTab - 1
                    setActiveTab(nextTabIndex)
                }}
            >
                <ChevronLeft />
            </div>
            {Array.from({ length: numberOfTabs }).map((_, index) => {
                if (
                    index === 0 ||
                    index === numberOfTabs - 1 ||
                    (index === activeTab + 2 && activeTab == 0) ||
                    (index === activeTab - 2 && activeTab == numberOfTabs - 1)
                ) {
                    return (
                        <div
                            key={index}
                            className={classes('page', { active: index === activeTab })}
                            onClick={() => setActiveTab(index)}
                        >
                            {index + 1}
                        </div>
                    )
                } else if (index === activeTab) {
                    return (
                        <div
                            key={index}
                            className={classes('page', { active: index === activeTab })}
                            onClick={() => setActiveTab(index)}
                        >
                            {index + 1}
                        </div>
                    )
                } else if (index === activeTab - 1 || index === activeTab + 1) {
                    return (
                        <div key={index} className={classes('page')} onClick={() => setActiveTab(index)}>
                            {index + 1}
                        </div>
                    )
                } else if (
                    (activeTab == numberOfTabs - 1 && index == numberOfTabs - 4) ||
                    (activeTab == 0 && index == 3) ||
                    (index == activeTab - 2 && index >= 1) ||
                    (index == activeTab + 2 && index <= numberOfTabs - 1)
                ) {
                    return (
                        <div key={index} className={classes('page')}>
                            ...
                        </div>
                    )
                }
                return null
            })}

            <div
                className={classes('svg-wrapper')}
                onClick={() => {
                    const nextTabIndex = activeTab == numberOfTabs - 1 ? 0 : activeTab + 1
                    setActiveTab(nextTabIndex)
                }}
            >
                <ChevronRight />
            </div>
        </div>
    )
}

const getBlogs = async (page, tag, blogPage) => {
    const fetchAPI = (await import('@/utils/strapiApi.js')).default
    let tagFilter = {
        tag: {
            $eq: tag,
        },
    }

    const {
        data,
        meta: { pagination },
    } = await fetchAPI('/blogs', {
        populate: 'deep',
        filter: tag ? tagFilter : null,
        pagination: {
            page,
            pageSize: blogPage ? 20 : 5,
            withCount: true,
        },
        sort: 'createdAt:DESC',
    })

    return { data, pagination }
}

export default BlogPostsGrid
